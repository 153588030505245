import LogoImg from '../../assets/ibai-logo.png'



const Navbar = () => (
    <nav className="bg-white py-4 shadow mb-2 px-16" >
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-10">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-14 rounded-md" src={LogoImg} alt="Logo" />
            <span className="text-5xl font-bold text-gray-800 ml-2">IBAI Foundation</span>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a
                href="#"
                className="text-gray-800 hover:bg-orange-500 hover:text-gray-100 px-3 py-2 rounded-md text-lg font-medium transition duration-100"
              >
                Home
              </a>
              <a
                href="#activities"
                className="text-gray-800 hover:bg-orange-500 hover:text-gray-100 px-3 py-2 rounded-md text-lg font-medium transition duration-100"
              >
                Activities
              </a>
              <a
                href="#footer"
                className="text-gray-800 hover:bg-orange-500 hover:text-gray-100 px-3 py-2 rounded-md text-lg font-medium transition duration-100"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
)

export default Navbar