import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

// style={{ position:"fixed", bottom: 0, width: '100%' }}
const Footer: React.FC = () => {
  return (
    <footer id="footer" className="bg-gray-800">
    <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-200">
            Address 1
          </h3>
          <p className="mt-2 text-sm text-gray-400">
            AL Hafeez Garden Phase 2, Main Canal Road, Lahore, Pakistan.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-200">
            Address 2
          </h3>
          <p className="mt-2 text-sm text-gray-400">
            Model City 2 Faisalabad.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-200">
            Address 3
          </h3>
          <p className="mt-2 text-sm text-gray-400">
            IBAI Street, Noorabad, Sialkot.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-200">
            Follow Us
          </h3>
          <div className="flex justify-center mt-2">
            <a href="#" className="mr-4 text-gray-400 hover:text-white">
              <FaFacebookF className="h-5 w-5" />
            </a>
            <a href="#" className="mr-4 text-gray-400 hover:text-white">
              <FaTwitter className="h-5 w-5" />
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              <FaInstagram className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-center">
        <p className="text-gray-400 text-sm">&copy; 2023 All Rights Reserved.</p>
      </div>
    </div>
  </footer>
  );
};
    // <footer className="bg-white pt-12">
    //   <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    //     <div className="md:flex md:items-center md:justify-between">
    //       <div className="flex justify-center md:order-2">
    //         <nav className="flex space-x-4">
    //           <a
    //             href="#"
    //             className="text-gray-400 hover:text-fe5f01 transition-colors duration-200"
    //           >
    //             Facebook
    //           </a>
    //           <a
    //             href="#"
    //             className="text-gray-400 hover:text-fe5f01 transition-colors duration-200"
    //           >
    //             Twitter
    //           </a>
    //           <a
    //             href="#"
    //             className="text-gray-400 hover:text-fe5f01 transition-colors duration-200"
    //           >
    //             Instagram
    //           </a>
    //         </nav>
    //       </div>
    //       <div className="mt-8 md:mt-0 md:order-1">
    //         <p className="text-center text-base text-gray-400">
    //           &copy; 2022 Welfare Foundation. All rights reserved.
    //         </p>
    //       </div>
    //       <div className="flex justify-center md:order-3">
    //         <nav className="flex space-x-4">
    //           <a
    //             href="#"
    //             className="text-gray-400 hover:text-fe5f01 transition-colors duration-200"
    //           >
    //             AL Hafeez Garden Phase 2,
    //             Main Canal Road,
    //             Lahore, Pakistan.
    //           </a>
    //           <a
    //             href="#"
    //             className="text-gray-400 hover:text-fe5f01 transition-colors duration-200"
    //           >
    //             Model City Faisalabad
    //           </a>
    //         </nav>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    // <footer className="bg-white py-6">
    //   <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    //       <div className="text-gray-700">
    //         <h3 className="font-bold mb-2">Address 1</h3>
    //         <p>AL Hafeez Garden Phase 2,</p>
    //         <p>Main Canal Road,</p>
    //         <p>Lahore, Pakistan.</p>
    //       </div>
    //       <div className="text-gray-700">
    //         <h3 className="font-bold mb-2">Address 2</h3>
    //         <p>Model City Faisalabad,</p>
    //         <p>Pakistan.</p>
    //       </div>
    //     </div>
    //   </div>
    // </footer>

export default Footer;