import ScholarShipImg from '../../assets/scholarship.jpg'

const Activities = () => {
  return (
    <section className="py-16" id="activities" >
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold text-gray-800">Activities</h2>
      </div>
      <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white shadow-md rounded-lg">
            <img src={ScholarShipImg} alt="Scholarship" className="w-full mb-6" />
          <div className="p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Scholarship for Students Up To Middle School
            </h3>
            <p className="mt-2 text-sm text-gray-500">
            The IBAI Foundation's scholarship program aims to support students up to middle school level by providing financial aid to those who may otherwise be unable to afford it. Through this program, we hope to help create a brighter future for these young scholars and equip them with the necessary tools to succeed.
            </p>
          </div>
        </div>
        {/* Add more activity cards here */}
      </div>
    </div>
  </section>
  );
};

export default Activities;