import Activities from "./components/activities/Activities";
import ContactForm from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div >
        <Navbar />
        <Hero />
        <Activities />
        {/* <ContactForm /> */}
        <Footer />
    </div>
  );
}

export default App;
