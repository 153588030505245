import HeroImg from "../../assets/hero.png";
import HeroRightImg from '../../assets/hero-2.png'

const Hero = () => (
      <div className="flex flex-col md:flex-row items-center  md:justify-between bg-white">
    <div className="md:w-1/2 px-24 text-center md:text-left">
      <h1 className="text-6xl font-bold">
        Together
        <br />
        We Can Change
        <br />
        The World
      </h1>
      <p className="text-lg mb-6 mt-6">
      At IBAI Foundation, we strive to help those in need by following the principles of Islamic welfare. With your support, we can make a positive impact on the lives of people all around the world.
      </p>
      {/* <button className="bg-orange-500 text-white py-3 px-6 rounded-lg shadow-md hover:bg-orange-600 transition duration-300">
        Donate Now
      </button> */}
    </div>
    <div className="w-full md:w-1/2">
      <img src={HeroRightImg} alt="Hero Image" className="w-full" />
    </div>
  </div>
);

export default Hero;
